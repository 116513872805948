import request from "./request";

// auth
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("admin/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const forgotPassword = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/auth/forgot/password`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getMomAndMe = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/danceClassForm`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteMomAndMe = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/danceClassForm/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Contact Us API
export const getContactUs = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contactUs`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getContactUsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editContactUs = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/contactUs/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteContactUs = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/contactUs/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


// Subscription API
export const getSubscription = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/subscription`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getSubscriptionById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/subscription/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editSubscription = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/subscription/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteSubscription = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/subscription/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



// Landing Page Leads API
export const getLandingPage = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/landingPage`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getLandingPageById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`admin/landingPage/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const editLandingPage = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`admin/landingPage/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteLandingPage = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`admin/landingPage/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
