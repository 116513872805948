import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* PAGES */
import DashboardLayout from "../layout/index";
import Login from "../pages/auth/login";

import ContactUs from "../pages/dashboard/contact-us";
import Subscription from "../pages/dashboard/subscription";
import LandingPage from "../pages/dashboard/landing-page";
import AryaSamajYagya from "../pages/dashboard/arya-samaj-yagya";
import DanceYoga from "../pages/dashboard/dance-yoga/index";
import MomAndMe from "../pages/dashboard/mom-me";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";
import KathakCertification from "../pages/dashboard/kathak-certification";
import YogGarba from "../pages/dashboard/yog-garba";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>

        {/* DASHBOARD ROUTES */}
        <Routes>
          <Route
            path="/contact-us"
            element={
              <DashboardLayout>
                <ContactUs />
              </DashboardLayout>
            }
          />
          <Route
            path="/subscription"
            element={
              <DashboardLayout>
                <Subscription />
              </DashboardLayout>
            }
          />
          <Route
            path="/landing-page"
            element={
              <DashboardLayout>
                <LandingPage />
              </DashboardLayout>
            }
          />
          <Route
            path="/arya-samaj-yagya"
            element={
              <DashboardLayout>
                <AryaSamajYagya />
              </DashboardLayout>
            }
          />
          <Route
            path="/dance-yoga"
            element={
              <DashboardLayout>
                <DanceYoga />
              </DashboardLayout>
            }
          />
          <Route
            path="/kathak-certification"
            element={
              <DashboardLayout>
                <KathakCertification />
              </DashboardLayout>
            }
          />
          <Route
            path="/mom-me"
            element={
              <DashboardLayout>
                <MomAndMe />
              </DashboardLayout>
            }
          />
          <Route
            path="/yog-garba"
            element={
              <DashboardLayout>
                <YogGarba />
              </DashboardLayout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
