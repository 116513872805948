/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";

/* ICON IMPORTS */
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";

/* API IMPORTS */
import { deleteMomAndMe, getMomAndMe } from "../../../service/api";
import { useEffect } from "react";

const MomAndMe = () => {
  /* MODAL STATES */
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const columns = [
    {
      name: "Mother Name",
      selector: (row) => (
        <div className="user-detail">
          <h5>{row.name}</h5>
        </div>
      ),
    },
    {
      name: "Mother Age",
      selector: (row) => <span className="light-text">{row.age} years</span>,
    },
    {
      name: "Phone",
      selector: (row) => <span className="light-text">{row.phone}</span>,
    },
    {
      name: "Email",
      selector: (row) => <span className="light-text">{row.email}</span>,
    },
    {
      name: "Child Name",
      selector: (row) => <span className="light-text">{row.childName}</span>,
    },
    {
      name: "Child Age",
      selector: (row) => (
        <span className="light-text">{row.childAge} years</span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewModal(!viewModal);
              setViewData(row);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            onClick={() => {
              setDeleteModal(!deleteModal);
              setDeleteId(row?._id);
            }}
            className="action-btn"
          />
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="pagination-loading-screen" style={{ marginTop: "15px" }}>
        <p style={{ fontSize: "16px", fontWeight: "600" }}>
          Please wait while we are fetching data...
        </p>
      </div>
    );
  };

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [fetchData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [search, setSearch] = useState(null);

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
  };
  const handlePageChange = async (e) => {
    setPage(e);
  };

  const [viewData, setViewData] = useState(null);

  const getLandingPageFormData = () => {
    let params;
    if (search) {
      params = {
        page: page,
        sizePerPage: sizePerPage,
        search,
      };
    } else {
      params = {
        page: page,
        sizePerPage: sizePerPage,
      };
    }
    setLoading(true);
    getMomAndMe(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        console.log(res);
        setTotalDocs(res?.data?.totalDocs);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error While Fetching Contact Us  List", error);
      });
  };

  const [deleteId, setDeleteId] = useState(null);

  const handleDelete = () => {
    deleteMomAndMe(deleteId)
      .then((res) => {
        getLandingPageFormData();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
      });
  };

  useEffect(() => {
    getLandingPageFormData();
  }, [page, sizePerPage, search]);

  console.log(fetchData);

  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>Mom and Me class leads</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your mom and me leads and edit their information
              </p>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? fetchData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                pagination
                paginationServer
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handlePerPageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* View MODAL */}
      <Modal
        centered
        backdrop="static"
        show={viewModal}
        onHide={() => setViewModal(!viewModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5
            style={{ fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}
          >
            Details
          </h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>
            <strong>Mother Name: </strong>
            {viewData?.name}
          </p>
          <p>
            <strong>Email: </strong>
            {viewData?.email}
          </p>
          <p>
            <strong>Phone Number: </strong>
            {viewData?.phone}
          </p>
          <p>
            <strong>Child Name: </strong>
            {viewData?.childName}
          </p>
          <p>
            <strong>Child Age: </strong>
            {viewData?.childAge} years
          </p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setViewModal(!viewModal)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        centered
        backdrop="static"
        show={deleteModal}
        onHide={() => setDeleteModal(!deleteModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Confirm Delete</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>Are you sure you want to delete these contact details ?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => {
              setViewData(null);
              setDeleteModal(!deleteModal);
            }}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default MomAndMe;
