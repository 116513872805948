/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import moment from "moment";

/* ICON IMPORTS */
import ViewIcon from "@iconscout/react-unicons/icons/uil-eye";
import DeleteIcon from "@iconscout/react-unicons/icons/uil-user-times";

/* Form Control */
import { useFormik } from "formik";
import * as yup from "yup";

/* API IMPORTS */
import {
  deleteSubscription,
  editSubscription,
  getSubscription,
} from "../../../service/api";
import { useEffect } from "react";

/* Validation Schema  */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is Required"),
  email: yup.string().email().required("Email Address is Required"),
  phone: yup.number().required("Phone Number is Required"),
  lastName: yup.string().required("Last Name is Required"),
  message: yup.string().required("Message On is Required"),
  status: yup.boolean(),
});
const Subscription = () => {
  /* MODAL STATES */
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const columns = [
    {
      name: "Sr. No",
      width: "10vh",
      selector: (row, index) => <span className="light-text">{index + 1}</span>,
    },
    {
      name: "Name",
      selector: (row) => (
        <div className="user-detail">
          <h5>
            {row?.members?.[0]?.firstName} {row?.members?.[0]?.lastName}{" "}
          </h5>
        </div>
      ),
    },
    {
      name: "Phone",
      selector: (row) => <span className="light-text">{row?.phoneNumber}</span>,
    },
    {
      name: "Email",
      grow: 2,
      selector: (row) => (
        <span className="light-text">{row?.members?.[0]?.email}</span>
      ),
    },
    {
      name: "Type",
      selector: (row) => (
        <span className="light-text">
          {row?.type === "INDIVIDUAL"
            ? "Individual"
            : row?.type === "GROUP5"
            ? "Group Of 5"
            : row?.type === "GROUP10"
            ? "Group of 10"
            : row?.type}
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={`light-text text-capitalize font-weight-bold ${
            row?.paymentStatus === "success" ? "text-success" : "text-danger"
          }`}
          style={{ fontWeight: "bold" }}
        >
          {row?.paymentStatus === "success" ? "Paid" : row?.paymentStatus}
        </span>
      ),
    },
    {
      name: "Created At",
      selector: (row) => (
        <span className="light-text">
          {moment(row?.createdAt).format("MMM, DD YYYY, hh:mm A")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <ViewIcon
            color="#8094AE"
            size="20"
            className="action-btn"
            onClick={() => {
              setViewModal(!viewModal);
              setViewData(row);
            }}
          />
          <DeleteIcon
            color="#8094AE"
            size="20"
            onClick={() => {
              setDeleteModal(!deleteModal);
              setDeleteId(row?._id);
            }}
            className="action-btn"
          />
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);
  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="pagination-loading-screen">
        <p>Please wait!</p>
        <p>We are fetching data </p>
      </div>
    );
  };

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [fetchData, setFetchedData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [search, setSearch] = useState(null);

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
  };
  const handlePageChange = async (e) => {
    setPage(e);
  };

  const [viewData, setViewData] = useState(null);
  const getLandingPageFormData = () => {
    let params;
    console.log("search", search);
    if (search) {
      params = {
        page: page,
        sizePerPage: sizePerPage,
        search,
      };
    } else {
      params = {
        page: page,
        sizePerPage: sizePerPage,
      };
    }
    setLoading(true);
    getSubscription(params)
      .then((res) => {
        setFetchedData(res?.data?.docs);
        console.log(res);
        setTotalDocs(res?.data?.totalDocs);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error While Fetching Subscription  List", error);
      });
  };

  const [deleteId, setDeleteId] = useState(null);
  const handleDelete = () => {
    deleteSubscription(deleteId)
      .then((res) => {
        getLandingPageFormData();
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        setDeleteModal(!deleteModal);
      });
  };

  const [editData, setEditData] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  useEffect(() => {
    if (editData !== null) {
      Object.entries(editData).forEach((entry) => {
        const [key, value] = entry;
        if (validationSchema?.fields?.hasOwnProperty(key)) {
          formikEditForm.setFieldValue(key, value);
        }
      });
    }
  }, [editData]);

  /* FORM VALUES HANDLING */
  const formikEditForm = useFormik({
    initialValues: {
      firstName: "",
      email: "",
      phone: "",
      lastName: "",
      message: "",
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setEditLoading(true);
      console.log("values", values);
      editSubscription(editData?._id, values)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            getLandingPageFormData();
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setEditLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setEditLoading(false);
        });
    },
  });

  useEffect(() => {
    getLandingPageFormData();
  }, [page, sizePerPage, search]);

  return (
    <section className="users">
      {/* BACKGROUND BANNER */}
      <div className="bg-blue-banner" />

      <div className="container">
        {/* PAGE HEADING */}
        <div className="page-head">
          <Row className="align-center">
            <Col lg="12" sm="12">
              <h1>Subscription Page Leads</h1>
              <p style={{ fontSize: "15px" }}>
                Glance through your Yog Garba page registered user and edit
                their information
              </p>
            </Col>
          </Row>
        </div>

        {/* USERS LIST */}
        <div className="users-table">
          <Card className="users-list">
            <Card.Header className="users-list-header">
              <div className="right-header">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search user"
                  name="searchUser"
                  id="searchUser"
                  onChange={(e) => {
                    console.log("text", e.target.value);
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </Card.Header>
            <Card.Body className="users-list-body">
              <DataTable
                columns={columns}
                data={totalDocs > 0 ? fetchData : []}
                progressPending={loading}
                progressComponent={<LoadingScreen />}
                pagination
                paginationServer
                paginationTotalRows={totalDocs}
                onChangeRowsPerPage={handlePerPageChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* View MODAL */}
      <Modal
        centered
        size="lg"
        show={viewModal}
        onHide={() => setViewModal(!viewModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Subscription Details</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body m-0">
          <div className="d-flex flex-row bd-highlight flex-wrap align-content-start ">
            {viewData?.groupName && (
              <p className="bd-highlight pe-5">
                <strong>Group Name: </strong>
                {viewData?.groupName}
              </p>
            )}
            <p className="bd-highlight pe-5">
              <strong>Type: </strong>
              {viewData?.type === "INDIVIDUAL"
                ? "Individual"
                : viewData?.type === "GROUP5"
                ? "Group Of 5"
                : viewData?.type === "GROUP10"
                ? "Group of 10"
                : viewData?.type}
            </p>
            <p className="bd-highlight pe-5">
              <strong>Phone Number: </strong>
              {viewData?.phoneNumber}
            </p>
            <p className="bd-highlight pe-5">
              <strong>Payment Status: </strong>
              <span
                className={`light-text text-capitalize font-weight-bold ${
                  viewData?.paymentStatus === "success"
                    ? "text-success"
                    : "text-danger"
                }`}
                style={{ fontWeight: "bold" }}
              >
                {viewData?.paymentStatus === "success"
                  ? "Paid"
                  : viewData?.paymentStatus}
              </span>
            </p>

            <p className="bd-highlight pe-5">
              <strong>Created At: </strong>{" "}
              {moment(viewData?.createdAt).format("MMM, DD YYYY, hh:mm A")}
            </p>
          </div>
          <div className="row w-full p-0 m-0" style={{fontSize:"13px"}}>
            {viewData?.members?.map((element, index) => {
              return (
                <div key={index} className="border     pt-3 col-4">
                   <p className="bd-highlight pe-5">
                      <strong> Member {index + 1}</strong>
                     
                    </p>
                  <div className="align-content-start">
                    <p >
                      <strong>Name: </strong>
                      {element?.firstName} {element?.lastName}
                    </p>
                    <p >
                      <strong>Experience: </strong>
                      {element?.level}
                    </p>
                    <p >
                      <strong>Phone Number: </strong>
                      {element?.phoneNumber}
                    </p>

                    <p >
                      <strong>Email: </strong>
                      {element?.email}
                    </p>
                    <p >
                      <strong>Best way to Connect: </strong>
                      {element?.connectWithYou}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => setViewModal(!viewModal)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        centered
        backdrop="static"
        show={deleteModal}
        onHide={() => setDeleteModal(!deleteModal)}
      >
        <Modal.Header className="delete-user-modal-header">
          <h5>Confirm Delete</h5>
        </Modal.Header>
        <Modal.Body className="delete-user-modal-body">
          <p>Are you sure you want to delete these subscription details ?</p>
        </Modal.Body>
        <Modal.Footer className="add-user-modal-footer">
          <Button
            className="cancel-btn"
            onClick={() => {
              setViewData(null);
              setDeleteModal(!deleteModal);
            }}
          >
            Cancel
          </Button>
          <Button className="proceed-btn" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT USER MODAL */}
      <Modal
        centered
        size="md"
        show={editModal}
        onHide={() => setEditModal(!editModal)}
      >
        <form
          onSubmit={formikEditForm.handleSubmit}
          id="edit-form"
          className="add-user-form"
        >
          <Modal.Header className="add-user-modal-header">
            <h5>Edit Contact</h5>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.firstName}
                    defaultValue={formikEditForm?.values?.firstName}
                  />
                  {formikEditForm.errors.firstName &&
                    formikEditForm.touched.firstName && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.firstName}
                      </small>
                    )}
                </div>
              </Col>

              <Col lg="6" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    id="lastName"
                    placeholder="Last Name"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.lastName}
                    defaultValue={formikEditForm?.values?.lastName}
                  />
                  {formikEditForm.errors.lastName &&
                    formikEditForm.touched.lastName && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.lastName}
                      </small>
                    )}
                </div>
              </Col>

              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="placeholder@roundtechsquare.com"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.email}
                    defaultValue={formikEditForm?.values?.email}
                  />
                  {formikEditForm.errors.email &&
                    formikEditForm.touched.email && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.email}
                      </small>
                    )}
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone Number"
                    defaultCountry="US"
                    className="phone-control"
                    onChange={(e) => {
                      formikEditForm.setFieldValue("phone", e);
                    }}
                    value={formikEditForm?.values?.phone}
                    defaultValue={formikEditForm?.values?.phone}
                  />
                  {formikEditForm.errors.phone &&
                    formikEditForm.touched.phone && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.phone}
                      </small>
                    )}
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="fName">
                    Message
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="message"
                    id="message"
                    placeholder="First Name"
                    onChange={formikEditForm.handleChange}
                    value={formikEditForm?.values?.message}
                    defaultValue={formikEditForm?.values?.message}
                  />
                  {formikEditForm.errors.message &&
                    formikEditForm.touched.message && (
                      <small style={{ color: "red" }}>
                        {formikEditForm.errors.message}
                      </small>
                    )}
                </div>
              </Col>

              {console.log("formikEditForm.errors", formikEditForm.errors)}
            </Row>
          </Modal.Body>
          <Modal.Footer className="add-user-modal-footer">
            <Button
              className="cancel-btn"
              onClick={() => setEditModal(!editModal)}
            >
              Cancel
            </Button>
            {editLoading ? (
              <Button className="proceed-btn" type="button">
                Please Wait...
              </Button>
            ) : (
              <Button className="proceed-btn" type="submit">
                Save details
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </section>
  );
};

export default Subscription;
